import React,{Component} from 'react'
import Header from './miniComponents/header'

class FAQs extends Component
{
   render(){
      return(
         <div id="blog">
            <Header page = 'FAQs' description = 'Frequently Asked Questions'/>
         <div style={{width:1000,height:500}}></div>
         </div>
      )

   }
   
}
export default FAQs
